import React, { useCallback, useEffect, useMemo, useState, memo } from "react";
import { Stack, Box, CircularProgress } from "@mui/material";
import styles from "./Leaderboard.module.css";
import axios from "axios";
import moment from "moment";
import { url } from "../../config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "../../UIComponents/DesignSystem/Typography";
import CustomDatePickerMonthYear from "../../UIComponents/DesignSystem/CustomDatePickerMonthYear";

const Leaderboard = memo(function Leaderboard() {
	const [month, setMonth] = useState({});
	const [monthlyData, setMonthlyData] = useState([]);
	const [loadingState, setLoading] = useState("idle");
	const [customDates, setCustomDates] = useState({
		startMonth: null
	});
	const [seeMore, setSeeMore] = useState(false);

	const monthNames = useMemo(
		() => [
			{ id: 0, title: "Jan" },
			{ id: 1, title: "Feb" },
			{ id: 2, title: "Mar" },
			{ id: 3, title: "Apr" },
			{ id: 4, title: "May" },
			{ id: 5, title: "Jun" },
			{ id: 6, title: "Jul" },
			{ id: 7, title: "Aug" },
			{ id: 8, title: "Sep" },
			{ id: 9, title: "Oct" },
			{ id: 10, title: "Nov" },
			{ id: 11, title: "Dec" }
		],
		[]
	);

	const getStartAndEndDateByMonthName = useCallback((monthId) => {
		const currentYear = new Date().getFullYear();

		const firstDate = moment(`${currentYear}-${monthId + 1}-01`)
			.startOf("month")
			.format("YYYY-MM-DD");
		const lastDate = moment(`${currentYear}-${monthId + 1}-01`)
			.endOf("month")
			.format("YYYY-MM-DD");
		return { firstDate, lastDate };
	}, []);

	useEffect(() => {
		const today = new Date();
		const currentDay = today.getDate();
		const currentMonth = today.getMonth();

		// If today's date is 7 or later, use current month, otherwise use the previous month
		const targetMonthId = currentDay >= 7 ? currentMonth : currentMonth - 1;

		// Handle year transition (December to January)
		const adjustedMonthId = targetMonthId < 0 ? 11 : targetMonthId;

		setMonth({ ...monthNames[adjustedMonthId] });
	}, [monthNames]);

	useEffect(() => {
		if (month.title || customDates.startMonth !== null) {
			let firstDate, lastDate;

			if (customDates.startMonth !== null) {
				({ firstDate, lastDate } = getStartAndEndDateByMonthName(customDates.startMonth.id));
			} else {
				({ firstDate, lastDate } = getStartAndEndDateByMonthName(month.id));
			}

			setLoading("loading");
			axios
				.get(`${url}/getLeaderBoard?startDate=${firstDate}&endDate=${lastDate}`)
				.then((res) => {
					const formattedData = res.data.map((entry) => ({
						...entry,
						total_time_hours: entry.total_time_hours.toFixed(2)
					}));
					setMonthlyData(formattedData);
					setLoading("success");
				})
				.catch((err) => {
					setLoading("error");
					console.error("Error: ", err);
				});
		}
	}, [month, customDates, getStartAndEndDateByMonthName]);

	return (
		<>
			<Stack
				alignItems="center"
				justifyContent="center"
				direction="row"
				gap={2}
				className={styles.header}
			>
				<Typography variant="Medium" fontSize={18}>
					Leaderboard
				</Typography>

				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CustomDatePickerMonthYear
						label="Month"
						onChange={(date) =>
							setCustomDates((prevDates) => ({
								...prevDates,
								startMonth: monthNames[date.month()]
							}))
						}
						disableFuture
					/>
				</LocalizationProvider>
			</Stack>
			<Box className={styles.container}>
				{loadingState === "loading" && (
					<Box className={styles.loadingBox}>
						<CircularProgress size={30} sx={{ color: "#7367f0" }} />
						<Typography variant="body2">Loading...</Typography>
					</Box>
				)}

				{loadingState === "error" && (
					<Box className={styles.errorBox}>
						<Typography color="error">Failed to load data. Please try again.</Typography>
					</Box>
				)}

				{loadingState === "success" &&
					(monthlyData.length ? (
						<>
							{monthlyData
								.filter((_, index) => seeMore || index < 10)
								.map(({ mavenlink_id, user_name, total_time_hours }, index) => (
									<Stack
										key={mavenlink_id}
										direction="row"
										justifyContent="start"
										alignItems="baseline"
										spacing={0.5}
										sx={{ width: "100%" }}
									>
										<Box className={styles.indexBox}>
											<Typography>{index + 1}</Typography>
										</Box>
										<Box className={styles.card}>
											<Box className={styles.userName}>{user_name}</Box>
											<Box className={styles.time}>{total_time_hours} hrs</Box>
										</Box>
									</Stack>
								))}

							{!seeMore && monthlyData.length > 10 && (
								<Typography
									sx={{
										textAlign: "center",
										cursor: "pointer",
										marginTop: "16px",
										color: "#7367F0"
									}}
									onClick={() => setSeeMore(true)}
								>
									See More
								</Typography>
							)}
						</>
					) : (
						<Box className={styles.noDataBox}>
							<Typography>No data available for this month.</Typography>
						</Box>
					))}
			</Box>{" "}
		</>
	);
});

export default Leaderboard;
