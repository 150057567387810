import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";
import { useTheme } from "@mui/material";

const CustomDatePickerMonthYear = (props) => {
	const { label, onChange, disableFuture, defaultValue } = props;
	const theme = useTheme();

	return (
		<MobileDatePicker
			label={label}
			views={["year", "month"]}
			onChange={onChange}
			slotProps={{
				textField: {
					size: "small",
					fullWidth: false,
					variant: "outlined",
					color: "purple",
					InputProps: {
						sx: {
							fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
							fontSize: "12px",
							lineHeight: "18px"
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end" disableRipple>
									<KeyboardArrowDownIcon fontSize="small" />
								</IconButton>
							</InputAdornment>
						)
					},
					InputLabelProps: {
						sx: {
							fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
							fontSize: 12
						}
					}
				},
				layout: {
					sx: {
						"& .MuiPickersYear-yearButton.Mui-selected": {
							backgroundColor: `${theme.palette.purple.main} !important`,
							color: "white" // Adjust text color for contrast
						},
						"& .MuiPickersMonth-monthButton.Mui-selected": {
							backgroundColor: `${theme.palette.purple.main} !important`,
							color: "white" // Adjust text color for contrast
						},
						"& .MuiDialogActions-root>.MuiButtonBase-root": {
							color: `${theme.palette.purple.main} !important`
						},
						"& .MuiPickersToolbar-root>.MuiTypography-root": {
							color: `${theme.palette.purple.main} !important`
						}
					}
				}
			}}
			defaultValue={dayjs(defaultValue)}
			closeOnSelect
			disableFuture={disableFuture}
		/>
	);
};

export default CustomDatePickerMonthYear;
